<template>
  <div class="profile">
    <h5 class="">您好，某某移动</h5>

    <div class="main mt-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="activeTab === 1 ? 'active' : ''" href="javascript:void(0)" @click="activeTab = 1">基本信息</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  :class="activeTab === 2 ? 'active' : ''" href="javascript:void(0)" @click="activeTab = 2">安全设置</a>
        </li>
      </ul>
      <div class="p-3" v-if="activeTab === 1">
        <div class="row">
          <div class="col-sm-2"><label>联系邮箱</label></div>
          <div class="col-sm-10">{{ user.mail }}</div>
        </div>
        <div class="row">
          <div class="col-sm-2"><label>手机号码</label></div>
          <div class="col-sm-10">{{ user.phone }}</div>
        </div>
        <div class="row">
          <div class="col-sm-2"><label>公司名称</label></div>
          <div class="col-sm-10">{{ user.company }}</div>
        </div>
        <div class="row">
          <div class="col-sm-2"><label>联系人</label></div>
          <div class="col-sm-10">{{ user.contact }}</div>
        </div>
        <div class="row">
          <div class="col-sm-2"><label>partner ID</label></div>
          <div class="col-sm-10">{{ user.partnerId }}</div>
        </div>
        <div class="row">
          <div class="col-sm-2"><label>Secret</label></div>
          <div class="col-sm-10">{{ user.secret }}</div>
        </div>
      </div>

      <div class="p-3" v-if="activeTab === 2">
        <form action="" class="form" @submit="onSubmit">
          <div class="form-group row">
            <div class="col-sm-2"><label>原密码</label></div>
            <div class="col-sm-4">
              <input type="password" name="oldPassword" v-model="form.oldPassword" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-2"><label>新密码</label></div>
            <div class="col-sm-4">
              <input type="password" name="password" v-model="form.password" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-2"><label>重复新密码</label></div>
            <div class="col-sm-4">
              <input type="password" name="repeatPassword" v-model="form.rePassword" class="form-control">
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary">保存</button>
          </div>
        </form>

      </div>

    </div>
  </div>
</template>
<script>
import User from "@/model/User";

export default {
  data() {
    return {

      form: {
        password: '',
        rePassword: '',
        oldPassword: '',
        mail: '',
        phone: ''
      },
      user: {},
      activeTab: 1
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      User.find().then(response => {
        if(response.data.code === 0) {
          this.user = response.data.data;
        }
      })
    },
    onSubmit(e) {
      e.preventDefault();
    }
  }
}
</script>